<template>
  <div>
    <q-form ref="editForm">
      <c-card :noHeader="true" class="cardClassDetailForm" topClass="topcolor-blue">
        <template slot="card-detail">
          <div class="col-12" v-if="checkTypes">
            <c-text
              required
              :editable="param.editable"
              label="장치구분"
              name="devicePartName"
              v-model="item.devicePartName">
            </c-text>
          </div>
          <div class="col-6">
            <c-textarea
              :editable="param.editable"
              label="내용물"
              name="inMaterials"
              :rows="5"
              v-model="item.inMaterials">
            </c-textarea>
          </div>
          <div class="col-6">
            <c-textarea
              :editable="param.editable"
              label="용량"
              name="volume"
              :rows="5"
              v-model="item.volume">
            </c-textarea>
          </div>
          <div class="col-3">
            <c-text
              suffix="MPa"
              :editable="param.editable"
              label="압력(MPa)-운전"
              name="pressOperation"
              v-model="item.pressOperation">
            </c-text>
          </div>
          <div class="col-3">
            <c-text
              suffix="MPa"
              :editable="param.editable"
              label="압력(MPa)-설계"
              name="pressDesign"
              v-model="item.pressDesign">
            </c-text>
          </div>
          <div class="col-3">
            <c-text
              suffix="℃"
              :editable="param.editable"
              label="온도(℃)-운전"
              name="tempOperation"
              v-model="item.tempOperation">
            </c-text>
          </div>
          <div class="col-3">
            <c-text
              suffix="℃"
              :editable="param.editable"
              label="온도(℃)-설계"
              name="tempDesign"
              v-model="item.tempDesign">
            </c-text>
          </div>
        </template>
      </c-card>
      <c-card :noHeader="true" class="cardClassDetailForm" topClass="topcolor-blue">
        <template slot="card-detail">
          <div class="col-4">
            <c-textarea
              :editable="param.editable"
              label="사용재질-본체"
              name="materialMain"
              :rows="1"
              v-model="item.materialMain">
            </c-textarea>
          </div>
          <div class="col-4">
            <c-textarea
              :editable="param.editable"
              label="사용재질-부속품"
              name="materialSubPart"
              :rows="1"
              v-model="item.materialSubPart">
            </c-textarea>
          </div>
          <div class="col-4">
            <c-textarea
              :editable="param.editable"
              label="사용재질-개스킷"
              name="materialGasket"
              :rows="1"
              v-model="item.materialGasket">
            </c-textarea>
          </div>
        </template>
      </c-card>
      <c-card :noHeader="true" class="cardClassDetailForm" topClass="topcolor-blue">
        <template slot="card-detail">
          <div class="col-3">
            <c-text
              :editable="param.editable"
              label="용법효율"
              name="weldingEfficiency"
              v-model="item.weldingEfficiency">
            </c-text>
          </div>
          <div class="col-3">
            <c-text
              suffix="mm"
              :editable="param.editable"
              label="계산두께(mm)"
              name="calculateThick"
              v-model="item.calculateThick">
            </c-text>
          </div>
          <div class="col-3">
            <c-text
              suffix="mm"
              :editable="param.editable"
              label="부식여유(mm)"
              name="corrosionAllowance"
              v-model="item.corrosionAllowance">
            </c-text>
          </div>
          <div class="col-3">
            <c-text
              suffix="mm"
              :editable="param.editable"
              label="사용두께(mm)"
              name="useThick"
              v-model="item.useThick">
            </c-text>
          </div>
        </template>
      </c-card>
      <c-card :noHeader="true" class="cardClassDetailForm" topClass="topcolor-blue">
        <template slot="card-detail">
          <div class="col-3">
            <c-select
              :editable="param.editable"
              :comboItems="afterHeatTreatYnItems"
              type="edit"
              itemText="codeName"
              itemValue="code"
              name="afterHeatTreatYn"
              label="후열처리 여부"
              v-model="item.afterHeatTreatYn"
            ></c-select>
          </div>
          <div class="col-3">
            <c-text
              suffix="%"
              :editable="param.editable"
              label="비파괴검사율(%)"
              name="nondestructPercent"
              v-model="item.nondestructPercent">
            </c-text>
          </div>
          <div class="col-6">
            <c-text
              :editable="param.editable"
              label="적용법령"
              name="applicableLaws"
              v-model="item.applicableLaws">
            </c-text>
          </div>
          <div class="col-12">
            <c-text
              :editable="param.editable"
              label="연결구 상태"
              name="conectorStatus"
              v-model="item.conectorStatus">
            </c-text>
          </div>
          <div class="col-12">
            <c-text
              :editable="param.editable"
              label="설계표준"
              name="designStandards"
              v-model="item.designStandards">
            </c-text>
          </div>
        </template>
      </c-card>
    </q-form>
  </div>
</template>

<script>
export default {
  name: 'device-machine-detail',
  props: {
    param: {
      type: Object,
      default: () => ({
        psiDeviceEquipmentId: '',
        deviceTypeCd: '',  // 장치
        devicePartCd: '',
        devicePartName: '',
        editable: false,
      }),
    },
    item: {
      type: Object,
      default: () => ({
        psiDeviceEquipmentId: '',  // 장치일련번호
        deviceTypeCd: '',  // 장치
        devicePartCd: '',  // 장치구분
        devicePartName: '',  // 장치구분명
        inMaterials: '',  // 내용물
        volume: '',  // 용량
        pressOperation: '',  // 압력(MPa)-운전
        pressDesign: '',  // 압력(MPa)-설계
        tempOperation: '',  // 온도(℃)-운전
        tempDesign: '',  // 온도(℃)-설계
        materialMain: '',  // 사용재질-본체
        materialSubPart: '',  // 사용재질-부속품
        materialGasket: '',  // 사용재질-개스킷
        weldingEfficiency: '',  // 용접효율
        calculateThick: '',  // 계산두께(mm)
        corrosionAllowance: '',  // 부식여유(mm)
        useThick: '',  // 사용두께(mm)
        afterHeatTreatYn: 'N',  // 후열처리여부
        nondestructMethod: '',  // 비파괴검사방법
        nondestructPercent: '',  // 비파괴검사율(%)
        applicableLaws: '',  // 적용법령
        conectorStatus: '',  // 적용법령
        designStandards: '',  // 적용법령
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
      }),
    },
  },
  computed: {
    checkTypes() {
      if (this.param.deviceTypeCd === 'DTC0000000') {
        return true
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      afterHeatTreatYnItems: [
        { code: 'Y', codeName: 'Y' },
        { code: 'N', codeName: 'N' },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  watch: {
    param() {
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.item.psiDeviceEquipmentId = this.param.psiDeviceEquipmentId;
      this.item.regUserId = this.$store.getters.user.userId;
      this.item.chgUserId = this.$store.getters.user.userId;
    },
  }
};
</script>
